<template>
  <div>
    <vx-card
      collapse-action
      :title="ntfGroup._id | date(true, true, false)"
      v-for="(ntfGroup, index) in notifications"
      :key="ntfGroup._id"
      :class="index > 0 ? 'mt-5' : ''">
      <notification-list
        :notifications="ntfGroup.notifications"
        only-time/>
    </vx-card>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner">
        <vue-element-loading
          active
          color="rgba(var(--vs-primary), 1)"
          spinner="mini-spinner"/>
      </div>
      <div slot="no-more"></div>
      <div slot="no-results">
        <feather-icon
          icon="BellOffIcon"
          svgClasses="h-64 w-64 mb-1 stroke-current text-primary" />
        <h2>
          {{ $t('$Pages.$Notifications.NoNotificationMsg') }}
        </h2>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import NotificationList from '@/layouts/components/navbar/components/notifications/NotificationDropDownList.vue';

/**
 * Notifications page
 *
 * @module views/pages/Notifications
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} notifications - notifications to show
 * @vue-data {number} count - total count of notifications
 * @vue-data {number} currentPage - current page
 * @vue-data {number} paginationPageSize - total number of pages
 * @vue-computed {Object} totalPages - total number of pages to show
 * @vue-event {void} fetchNotifications - fetch notifications
 * @vue-event {void} infiniteHandler - handle the infinite scroll
 */
export default {
  name: 'Notifications',
  components: {
    NotificationList,
    InfiniteLoading,
  },
  data() {
    return {
      notifications: [],
      count: 0,
      currentPage: 1,
      paginationPageSize: 10,
    };
  },
  computed: {
    paramsToFetch() {
      return {
        limit: this.paginationPageSize,
        skip: (this.currentPage - 1) * this.paginationPageSize,
      };
    },
    totalPages() {
      return Math.ceil(this.count / this.paginationPageSize);
    },
  },
  methods: {
    ...mapActions({
      updateNotificationsAsViewed: 'notification/updateNotificationsAsViewed',
      fetchAllNotifications: 'notification/fetchAllNotifications',
      fetchAllNotificationsByDay: 'notification/fetchAllNotificationsByDay',
    }),
    async fetchNotifications() {
      const resp = await this.fetchAllNotificationsByDay(this.paramsToFetch);

      if (resp) {
        this.notifications = this.notifications.concat(resp.data || []);
        this.count = resp.count || 0;
      }
    },
    async infiniteHandler($state) {
      await this.fetchNotifications();
      this.currentPage += 1;

      if (this.currentPage > this.totalPages) {
        if (this.count > 0) {
          $state.loaded();
        }

        $state.complete();
      } else {
        $state.loaded();
      }
    },
  },
};

</script>
